.Pill {
  @apply flex
	max-w-max
	items-center justify-center
	px-[10px]
	font-[600];
  :global {
  }
}

.PillRed {
  @apply bg-roseCream
	text-oxblood;
}

.PillYellow {
  @apply bg-sunShy
	text-ochre;
}

.PillGreen {
  @apply bg-leaf
	text-forest;
}

.PillBlue {
  @apply bg-clearSky
	text-electricBlue;
}

.PillGray {
  @apply bg-gray-100
	text-gray-400;
}

.PillBlack {
  @apply bg-black
	text-white;
}
